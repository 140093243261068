<template>
    <div class="pfm-delete-account-modal">
        <mds-modal
            @mds-modal-dismissed="emitDeleteRejected"
            :visible="true"
            tabindex="-1"
            aria-labelledby="title-id"
            ref="deleteAccountModal"
            width="600px">
            <mds-section border-position="bottom" bold>
                <template v-slot:mds-section-title>
                    <span id="title-id">{{title}}</span>
                </template>
                <template right-aligned v-slot:mds-section-actions>
                    <mds-button
                        ref="modalCloseBtn"
                        :aria-label="labels.closeModal"
                        aria-labelledby="ariaCloseModal"
                        aria-describedby="ariaCloseModal"
                        @click="emitDeleteRejected"
                        icon="remove"
                        type="button"
                        variation="icon-only">
                        <span class="visually-hidden">{{labels.closeModal}}</span>
                    </mds-button>
                </template>
                <div class="pfm-delete-account-modal__account">
                    <h3
                        v-html="account.title"
                        class="pfm-delete-account-modal__account-title"></h3>
                    <div class="pfm-delete-account-modal__account-sub-title">
                        <div
                            v-html="account.institutionName"
                            :aria-description="labels.ariaInstitutionName"
                            class="pfm-delete-account-modal__account-institution"></div>
                    </div>
                </div>
                <div class="pfm-delete-account-modal__detail">
                    <span v-if="detailText && displayUnlink">{{detailText}}</span>
                    <span v-else>{{cashflowEnabaleText}}</span>
                </div>
                <div class="pfm-delete-account-modal__form">
                    <mds-fieldset
                        :legend="acceptText"
                        size="touch"
                        variation="checkbox-group">
                        <mds-checkbox
                            v-model="checkUndone"
                            ref="deleteUndoneCheckbox"
                            size="touch"
                            :aria-label="undoneLabel"
                            aria-required="true"
                            value="true">{{undoneLabel}}</mds-checkbox>
                        <mds-checkbox
                            v-model="checkPermanent"
                            size="touch"
                            :aria-label="permanentLabel"
                            aria-required="true"
                            value="true">{{permanentLabel}}</mds-checkbox>
                    </mds-fieldset>
                </div>
                <div
                    class="pfm-delete-account-modal__detail">
                    <span
                        v-if="switchText && displayUnlink">
                        {{switchText}}</span>
                    <a
                        v-if="switchText && displayUnlink && switchTextLink"
                        ref="switchLink"
                        href="javascript:void(0)"
                        @click="handleSwitchEvent"
                        class="switchLink"
                        data-switch="true">
                        {{switchTextLink}}</a>
                </div>
                <mds-button-container
                    role="group"
                    aria-label="Action">
                    <mds-button
                        @click="emitDeleteRejected"
                        size="touch"
                        v-on:keydown.tab.exact="focusDeleteAccountBtn"
                        v-on:keydown.down="rejectBtnDownArrowEvent"
                        type="button">{{rejectButtonLabel}}</mds-button>
                    <mds-button
                        @click="emitDeleteAccepted"
                        ref="deleteAccountBtn"
                        :aria-label="labels.ariaDeleteAccountBtn"
                        :aria-description="labels.ariaDeleteAccountBtn"
                        :disabled="disableSubmit"
                        v-on:keydown.tab.exact="focusModalCloseBtn"
                        v-on:keydown.down="focusModalCloseBtn"
                        size="touch"
                        type="button">
                        <span aria-hidden="true">{{acceptButtonLabel}}</span></mds-button>
                </mds-button-container>
                <p id="ariaCloseModal" hidden>{{labels.closeModal}}</p>
                <p id="ariaDeleteAccountBtn" hidden>{{labels.ariaDeleteAccountBtn}}</p>
            </mds-section>
        </mds-modal>
    </div>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsCheckbox from '@mds/checkbox';
import MdsFieldset from '@mds/fieldset';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import { canUnlinkAccount, canDeleteAccount } from '../helpers/account';
import { isValidElement } from '../helpers/elements';
import { DISPLAY_TYPES } from '../helpers/display-type';
import forceNextTick from '../helpers/vueForceNextTick';

export default {
    components: {
        MdsButton,
        MdsButtonContainer,
        MdsCheckbox,
        MdsFieldset,
        MdsModal,
        MdsSection
    },
    computed: {
        displayUnlink() {
            const { account } = this;
            return canUnlinkAccount(account)
                && account.displayTypeName !== DISPLAY_TYPES.INVESTMENTS
                && this.isCashflowEnabled
                && account.source !== 'unlinked';
        },
        canUnlink() {
            const { account } = this;
            return canUnlinkAccount(account);
        },
        canDelete() {
            return canDeleteAccount(this.account);
        },
        disableSubmit() {
            const { checkPermanent, checkUndone } = this;

            return checkPermanent === false
                || checkUndone === false;
        }
    },
    data() {
        return {
            checkPermanent: false,
            checkUndone: false
        };
    },
    methods: {
        emitDeleteAccepted() {
            const { account, canUnlink, canDelete } = this;
            if (canDelete) {
                this.$emit('pfm-delete-accepted', account);
            }
            // If the account exists within BAA, unlink it to avoid orphans
            if (canUnlink) {
                this.$emit('pfm-unlink-accepted', account);
            }
        },
        emitDeleteRejected() {
            this.$emit('pfm-delete-rejected', 'deleteAccountModal');
        },
        emitShowUnlinkAccount() {
            this.$emit('pfm-show-unlink-account', this.account);
        },
        handleSwitchEvent(e) {
            const { target } = e || {};

            // Only emit the event if a switch link is clicked
            if (isValidElement(target) && target.dataset.switch) {
                this.emitShowUnlinkAccount();
            }
        },
        rejectBtnDownArrowEvent() {
            (!this.disableSubmit) ? this.focusDeleteAccountBtn() : this.focusModalCloseBtn();
        },
        focusDeleteAccountBtn() {
            const deleteAccountBtn = this.$refs.deleteAccountBtn.$el;
            if (!deleteAccountBtn) { return; }
            deleteAccountBtn.focus();
        },
        focusModalCloseBtn() {
            forceNextTick(() => {
                const closeBtn = this.$refs.modalCloseBtn.$el;
                if (!closeBtn) { return; }
                closeBtn.focus();
            });
        },
        modalCloseBtnShiftTabEvent() {
            const modalCloseBtn = this.$refs.modalCloseBtn.$el;
            if (!modalCloseBtn) { return; }
            modalCloseBtn.addEventListener('keydown', e => {
                if (e.shiftKey && e.key === 'Tab' && !this.disableSubmit) {
                    this.focusDeleteAccountBtn();
                }
            });
        },
        afterComponentLoad() {
            this.focusModalCloseBtn();
            if (!this.displayUnlink) {
                const deleteModalWrapper = document.querySelector('.pfm-delete-account-modal .mds-modal__wrapper__pfm');
                if (deleteModalWrapper) {
                    deleteModalWrapper.classList.add('modal-height');
                }
            }
            forceNextTick(() => {
                const checkboxList = document.querySelectorAll('.mds-checkbox__text__pfm');
                for (let i = 0; i < checkboxList.length; i += 1) {
                    checkboxList[i].setAttribute('aria-hidden', true);
                }
            });
            this.modalCloseBtnShiftTabEvent();
        }
    },
    mounted() {
        this.afterComponentLoad();
    },
    name: 'pfm-delete-account-modal',
    props: {
        labels: {
            default: () => ({}),
            type: Object
        },
        acceptButtonLabel: {
            default: 'Delete account',
            type: String
        },
        acceptText: {
            default: 'Note: To delete this account, you have to accept all the required following:',
            type: String
        },
        account: {
            required: true,
            type: Object
        },
        detailText: {
            // eslint-disable-next-line max-len
            default: 'This will permanently delete the account. You will not be able to track spending with this account.',
            type: String
        },
        cashflowEnabaleText: {
            default: 'This will stop syncing and permanently delete the account.',
            type: String
        },
        permanentLabel: {
            default: 'This account will be permanently erased from our records',
            type: String
        },
        rejectButtonLabel: {
            default: 'Nevermind',
            type: String
        },
        switchText: {
            // eslint-disable-next-line max-len
            default: 'Still want to keep your historical data? ',
            type: String
        },
        switchTextLink: {
            default: 'Unlink your account',
            type: String
        },
        title: {
            default: 'Delete account?',
            type: String
        },
        undoneLabel: {
            default: 'This action cannot be undone',
            type: String
        },
        isCashflowEnabled: {
            default: true,
            type: Boolean
        }
    }
};
</script>

<style lang="scss" module>
@import '~cwp-dev-shared/styles/variables';

@mixin pfm-account-reset-space ($margin: 0, $padding: 0) {
    margin: $margin;
    padding: $padding;
}

:global {
    .pfm-delete-account-modal {
        &__account {
            @include pfm-account-reset-space();
        }

        &__detail,
        &__form,
        &__switch {
            margin-top: $mds-space-2-x;
        }

        &__account-institution {
            overflow: hidden;
            text-overflow: clip;
            white-space: nowrap;
        }

        &__account-title {
            @include mds-level-5-heading();
            @include pfm-account-reset-space();
        }

        &__account-sub-title {
            @include mds-body-text-m();
            color: $mds-text-color-secondary-on-light;
        }

        &__detail {
            @include mds-body-text-l();
        }

        [data-switch="true"] {
            cursor: pointer;
            text-decoration: underline;
        }

        .mds-modal__wrapper__pfm {
            display: block;
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 625px;
            max-width: 100% !important;
            height: 446px;
            max-height: 100% !important;
            z-index: 100;
        }

        .modal-height {
            height: 375px;
        }

        .mds-modal__content__pfm {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
        }

        .switchLink {
            display: inline-block;
            position: absolute;
            color: #1e1e1e;
            padding-left: $mds-space-1-x;
            z-index: 1;
        }

        .visually-hidden:not(:focus):not(:active) {
            clip: rect(0 0 0 0);
            clip-path: inset(100%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        *:focus {
            outline: $accessibility-focus-outline;
            box-shadow: $accessibility-focus-box-shadow;
        }
    }
}

:global(.pfm-delete-account-modal) {
    .mds-button-container {
        margin-top: $mds-space-2-x;
    }
}
</style>
