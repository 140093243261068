<template>
    <div class="pfm-unlink-account-modal">
        <mds-modal
            @mds-modal-dismissed="emitUnlinkRejected"
            :visible="true"
            tabindex="-1"
            aria-labelledby="title-id"
            ref="unlinkAccountModal"
            width="600px">
            <mds-section border-position="bottom" bold>
                <template v-slot:mds-section-title>
                    <span id="title-id">{{title}}</span>
                </template>
                <template right-aligned v-slot:mds-section-actions>
                    <mds-button
                        ref="modalCloseBtn"
                        :aria-label="labels.closeModal"
                        aria-labelledby="ariaCloseModal"
                        aria-describedby="ariaCloseModal"
                        @click="emitUnlinkRejected"
                        icon="remove"
                        type="button"
                        variation="icon-only">
                        <span class="visually-hidden">{{labels.closeModal}}</span>
                    </mds-button>
                </template>
                <div class="pfm-unlink-account-modal__account">
                    <h3
                        v-html="account.title"
                        class="pfm-unlink-account-modal__account-title"></h3>
                    <div class="pfm-unlink-account-modal__account-sub-title">
                        <div
                            v-html="account.institutionName"
                            :aria-description="labels.ariaInstitutionName"
                            class="pfm-unlink-account-modal__account-institution"></div>
                    </div>
                </div>
                <div class="pfm-unlink-account-modal__detail"
                     v-if="detailText"
                     v-html="detailText"></div>
                <div class="pfm-unlink-account-modal__form">
                    <mds-fieldset
                        :legend="acceptText"
                        size="touch"
                        variation="checkbox-group">
                        <mds-checkbox
                            ref="unlinkUndoneCheckbox"
                            v-model="checkUndone"
                            size="touch"
                            :aria-label="undoneLabel"
                            aria-required="true"
                            value="true">{{undoneLabel}}</mds-checkbox>
                        <mds-checkbox
                            v-model="checkDuration"
                            size="touch"
                            :aria-label="durationLabel"
                            aria-required="true"
                            value="true">{{durationLabel}}</mds-checkbox>
                        <mds-checkbox
                            v-model="checkPermanent"
                            size="touch"
                            :aria-label="permanentLabel"
                            aria-required="true"
                            value="true">{{permanentLabel}}</mds-checkbox>
                        <mds-checkbox
                            v-model="checkDuplicate"
                            size="touch"
                            :aria-label="duplicateLabel"
                            aria-required="true"
                            value="true">{{duplicateLabel}}</mds-checkbox>
                    </mds-fieldset>
                </div>
                <div
                    class="pfm-unlink-account-modal__detail">
                    <span
                        v-if="switchText">
                        {{switchText}}</span>
                    <a
                        v-if="switchTextLink"
                        ref="switchLink"
                        href="javascript:void(0)"
                        @click="handleSwitchEvent"
                        class="switchLink"
                        data-switch="true">
                        {{switchTextLink}}</a>
                </div>
                <mds-button-container
                    role="group"
                    aria-label="Action">
                    <mds-button
                        @click="emitUnlinkRejected"
                        size="touch"
                        v-on:keydown.tab.exact="focusUnlinkAccountBtn"
                        v-on:keydown.down="rejectBtnDownArrowEvent"
                        type="button">{{rejectButtonLabel}}</mds-button>
                    <mds-button
                        @click="emitUnlinkAccepted"
                        ref="unlinkAccountBtn"
                        :aria-label="labels.ariaUnlinkAccountBtn"
                        :aria-description="labels.ariaUnlinkAccountBtn"
                        :disabled="disableSubmit"
                        v-on:keydown.tab.exact="focusModalCloseBtn"
                        v-on:keydown.down="focusModalCloseBtn"
                        size="touch"
                        type="button">
                        <span aria-hidden="true">{{acceptButtonLabel}}</span></mds-button>
                </mds-button-container>
                <p id="ariaCloseModal" hidden>{{labels.closeModal}}</p>
                <p id="ariaUnlinkAccountBtn" hidden>{{labels.ariaUnlinkAccountBtn}}</p>
            </mds-section>
        </mds-modal>
    </div>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsCheckbox from '@mds/checkbox';
import MdsFieldset from '@mds/fieldset';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import { isValidElement } from '../helpers/elements';
import forceNextTick from '../helpers/vueForceNextTick';

export default {
    components: {
        MdsButton,
        MdsButtonContainer,
        MdsCheckbox,
        MdsFieldset,
        MdsModal,
        MdsSection
    },
    computed: {
        disableSubmit() {
            const {
                checkDuplicate,
                checkDuration,
                checkPermanent,
                checkUndone
            } = this;

            return checkDuplicate === false
                || checkDuration === false
                || checkPermanent === false
                || checkUndone === false;
        }
    },
    data() {
        return {
            checkDuplicate: false,
            checkDuration: false,
            checkPermanent: false,
            checkUndone: false
        };
    },
    methods: {
        emitUnlinkAccepted() {
            this.$emit('pfm-unlink-accepted', this.account);
        },
        emitUnlinkRejected() {
            this.$emit('pfm-unlink-rejected', 'unlinkAccountModal');
        },
        emitShowDeleteAccount() {
            this.$emit('pfm-show-delete-account', this.account);
        },
        handleSwitchEvent(e) {
            const { target } = e || {};

            // Only emit the event if a switch link is clicked
            if (isValidElement(target) && target.dataset.switch) {
                this.emitShowDeleteAccount();
            }
        },
        rejectBtnDownArrowEvent() {
            (!this.disableSubmit) ? this.focusUnlinkAccountBtn() : this.focusModalCloseBtn();
        },
        focusUnlinkAccountBtn() {
            const unlinkAccountBtn = this.$refs.unlinkAccountBtn.$el;
            if (!unlinkAccountBtn) { return; }
            unlinkAccountBtn.focus();
        },
        focusModalCloseBtn() {
            forceNextTick(() => {
                const modalCloseBtn = this.$refs.modalCloseBtn.$el;
                if (!modalCloseBtn) { return; }
                modalCloseBtn.focus();
            });
        },
        modalCloseBtnShiftTabEvent() {
            const modalCloseBtn = this.$refs.modalCloseBtn.$el;
            if (!modalCloseBtn) { return; }
            modalCloseBtn.addEventListener('keydown', e => {
                if (e.shiftKey && e.key === 'Tab' && !this.disableSubmit) {
                    this.focusUnlinkAccountBtn();
                }
            });
        },
        afterComponentLoad() {
            this.focusModalCloseBtn();
            forceNextTick(() => {
                const checkboxList = document.querySelectorAll('.mds-checkbox__text__pfm');
                for (let i = 0; i < checkboxList.length; i += 1) {
                    checkboxList[i].setAttribute('aria-hidden', true);
                }
            });
            this.modalCloseBtnShiftTabEvent();
        }
    },
    mounted() {
        this.afterComponentLoad();
    },
    name: 'pfm-unlink-account-modal',
    props: {
        labels: {
            default: () => ({}),
            type: Object
        },
        acceptButtonLabel: {
            default: 'Unlink account',
            type: String
        },
        acceptText: {
            default: 'Note: To unlink this account, you have to accept all the required following:',
            type: String
        },
        account: {
            required: true,
            type: Object
        },
        detailText: {
            // eslint-disable-next-line max-len
            default: 'This will stop syncing the account and will not permanently delete it. You\'ll be able to see past spending with this account.',
            type: String
        },
        duplicateLabel: {
            default: 'This account will appear twice if you add this account again',
            type: String
        },
        durationLabel: {
            default: 'Only historical data will be shown',
            type: String
        },
        permanentLabel: {
            default: 'This account will not be permanently deleted',
            type: String
        },
        rejectButtonLabel: {
            default: 'Nevermind',
            type: String
        },
        switchText: {
            // eslint-disable-next-line max-len
            default: 'Want to permanently remove the data? ',
            type: String
        },
        switchTextLink: {
            default: 'Delete your account',
            type: String
        },
        title: {
            default: 'Unlink account?',
            type: String
        },
        undoneLabel: {
            default: 'This action cannot be undone',
            type: String
        }
    }
};
</script>

<style lang="scss" module>
@import '~cwp-dev-shared/styles/variables';

@mixin pfm-account-reset-space ($margin: 0, $padding: 0) {
    margin: $margin;
    padding: $padding;
}

:global {
    .pfm-unlink-account-modal {
        &__account {
            @include pfm-account-reset-space();
        }

        &__detail,
        &__form,
        &__switch {
            margin-top: $mds-space-2-x;
        }

        &__account-institution {
            overflow: hidden;
            text-overflow: clip;
            white-space: nowrap;
        }

        &__account-title {
            @include mds-level-5-heading();
            @include pfm-account-reset-space();
        }

        &__account-sub-title {
            @include mds-body-text-m();
            color: $mds-text-color-secondary-on-light;
        }

        &__detail {
            @include mds-body-text-l();
        }

        [data-switch="true"] {
            cursor: pointer;
            text-decoration: underline;
        }

        .mds-modal__wrapper__pfm {
            display: block;
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 625px;
            max-width: 100% !important;
            height: 542px;
            max-height: 100% !important;
            z-index: 100;
        }

        .mds-modal__content__pfm {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
        }

        .switchLink {
            display: inline-block;
            position: absolute;
            color: #1e1e1e;
            padding-left: $mds-space-1-x;
            z-index: 1;
        }

        .visually-hidden:not(:focus):not(:active) {
            clip: rect(0 0 0 0);
            clip-path: inset(100%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        *:focus {
            outline: $accessibility-focus-outline;
            box-shadow: $accessibility-focus-box-shadow;
        }
    }
}

:global(.pfm-unlink-account-modal) {
    .mds-button-container {
        margin-top: $mds-space-2-x;
    }
}
</style>
