<template>
    <div
        class="pfm-layout"
        :lang="languageCode"
        :data-pfm-component="componentVersion">
        <div
            class="visually-hidden"
            aria-live="polite">
            <div
                role="status"
                id="pfmScreenReaderStatus">
            </div>
        </div>
        <pfm-communicator
            :data-sources="dataSourcesString"
            :mwc-id="`${mwcId}.pfmCommunicator`"
            :parent-mwc-id="mwcId"
            :pfm-api-env="pfmApiEnv"
            :is-account-list-component="true" />
        <div
            v-if="middlewareReady"
            data-feature="account-list">
            <pfm-account-list
                :baa-data-loaded="baaDataLoaded"
                @baa-delete-credential="emitterFactory(routes.pfmAccountList, $event)"
                :baa-notifications="baaResponseErrors"
                :config="accountListConfig"
                :loading="true"
                :mwc-id="`${this.mwcId}.pfmAccountList`"
                @pfm-account-aggregation-failed="emitterFactory(routes.pfmAccountList, $event)"
                @pfm-account-aggregation-succeeded="emitterFactory(routes.pfmAccountList, $event)"
                @pfm-baa-data-loaded="onBaaDataLoaded"
                @pfm-net-worth-data-loaded="onNetWorthDataLoaded"
                @pfm-component-destroyed="emitterFactory(routes.pfmAccountList, $event)"
                @pfm-component-rendered="emitterFactory(routes.pfmAccountList, $event)"
                @pfm-credential-errors="emitterFactory(routes.pfmAccountList, $event)"
                @pfm-link-account="showBaaModal"
                @pfm-show-delete-account="onShowDeleteAccount"
                @pfm-show-support-modal="emitterFactory(routes.pfmAccountList, $event)"
                @pfm-show-unlink-account="onShowUnlinkAccount"
                @pfm-update-credential="onUpdateCredential" />
            <mds-modal
                v-if="useBaaModal"
                v-model="isModalVisible"
                :action-required="true"
                :title="labels.accountAggregation"
                :title-hidden="true"
                width="600px">
                <template v-slot:default>
                    <mds-empty-state v-if="showBaaError"
                                     size="large"
                                     :title="labels.baaErrors.title"
                                     :message="baaErrorMessage"
                    />
                    <mstar-aggregation-consumer-accountsetup
                        :rest-url="restUrl"
                        :corece-base-url="aggregationHost"
                        :auth-context="authContextString"
                        :custom-fonts="customFonts"
                        internal-debug="true"
                        :override-css-file="overrideCssFile"
                        :route="route"
                        :translate-file-path="translateFilePath"
                        :ui-config-file="uiConfigFile"
                        @badAuthentication="baaServerError($event),
                                            emitterFactory(routes.byAllAccounts, $event)
                                            emitWrappedEvent('baa-bad-authentication', $event)"
                        @badConfiguration="baaServerError($event),
                                           emitterFactory(routes.byAllAccounts, $event),
                                           emitWrappedEvent('baa-bad-configuration', $event)"
                        @dataChanged="emitterFactory(routes.byAllAccounts, $event),
                                      emitWrappedEvent('baa-data-changed', $event)"
                        @dataChangedAsync="emitterFactory(routes.byAllAccounts, $event),
                                           emitWrappedEvent('baa-data-changed-async', $event)"
                        @internalError="baaServerError($event),
                                        emitterFactory(routes.byAllAccounts, $event),
                                        emitWrappedEvent('baa-internal-error', $event)"
                        @pfm-account-aggregation-failed="emitterFactory(routes.pfmAccountList, $event)"
                        @pfm-account-aggregation-succeeded="accountLinkedFunc(),emitterFactory(routes.pfmAccountList, $event)"
                        @userExit="onUserExit($event),
                                   emitWrappedEvent('baa-user-exit', $event)" />
                </template>
                <template v-slot:mds-modal-actions>
                    <span v-if="!showBaaError">&nbsp;</span>
                    <button v-else
                            aria-label="Remove"
                            type="button"
                            role="button"
                            class="icon mds-button mds-button--icon-only mds-icon__close"
                            @click="closeBaaModal">
                        <mds-icon name="remove" />
                    </button>
                </template>
            </mds-modal>
            <pfm-delete-account-modal
                v-if="accountToDelete"
                :accept-button-label="labels.deleteAccount.acceptButtonLabel"
                :accept-text="labels.deleteAccount.acceptText"
                :account="accountToDelete"
                :labels="labels"
                :detail-text="labels.deleteAccount.detailText"
                :duration-label="labels.deleteAccount.durationLabel"
                :permanent-label="labels.deleteAccount.permanentLabel"
                :reject-button-label="labels.deleteAccount.rejectButtonLabel"
                @pfm-delete-accepted="onDeleteAccepted"
                @pfm-delete-rejected="resetAccounts"
                @pfm-show-unlink-account="onShowUnlinkAccount"
                @pfm-unlink-accepted="onUnlinkAccepted"
                :switch-text="labels.deleteAccount.switchText"
                :switch-link="labels.deleteAccount.switchTextLink"
                :title="labels.deleteAccount.title"
                :is-cashflow-enabled="isCashflowEnabled"
                :undone-label="labels.deleteAccount.undoneLabel" />
            <pfm-unlink-account-modal
                v-if="accountToUnlink"
                :accept-button-label="labels.unlinkAccount.acceptButtonLabel"
                :accept-text="labels.unlinkAccount.acceptText"
                :account="accountToUnlink"
                :labels="labels"
                :detail-text="labels.unlinkAccount.detailText"
                :duplicate-label="labels.unlinkAccount.duplicateLabel"
                :duration-label="labels.unlinkAccount.durationLabel"
                :permanent-label="labels.unlinkAccount.permanentLabel"
                :reject-button-label="labels.unlinkAccount.rejectButtonLabel"
                @pfm-unlink-accepted="onUnlinkAccepted"
                @pfm-unlink-rejected="resetAccounts"
                @pfm-show-delete-account="onShowDeleteAccount"
                :switch-text="labels.unlinkAccount.switchText"
                :switch-link="labels.unlinkAccount.switchTextLink"
                :title="labels.unlinkAccount.title"
                :is-cashflow-enabled="isCashflowEnabled"
                :undone-label="labels.unlinkAccount.undoneLabel" />
        </div>
        <pfm-loader
            v-else
            :is-full-page="true" />
    </div>
</template>

<script>
import Vue from 'vue';
import 'mstar-aggregation-consumer-accountsetup';
import MdsModal from '@mds/modal';
import MdsIcon from '@mds/icon';
import MdsEmptyState from '@mds/empty-state';
import PfmAccountList from 'pfm-account-list';
import { screenReaderStatus } from 'pfm-shared/helpers/elements';
import PfmContainerMixin from 'pfm-shared/mixins/pfm-container';
import { isValidObject, isValidString, whichString } from 'pfm-shared/helpers/valid';
import PfmCommunicator from 'pfm-communicator';
import PfmDeleteAccountModal from 'pfm-shared/components/pfm-delete-account-modal';
import PfmLoader from 'pfm-shared/components/pfm-loader';
import PfmUnlinkAccountModal from 'pfm-shared/components/pfm-unlink-account-modal';
import forceNextTick from 'pfm-shared/helpers/vueForceNextTick';
import { mwcComponentIdentifier, mwcComponentName, version } from '../package.json';
import DEFAULT_CONFIG from './_config';

const ADD_ACCOUNT_ROUTE = '/add-account;allowMultipleAdds=true';

export default {
    async beforeCreate() {
        Vue.customElement('pfm-communicator', PfmCommunicator);
        Vue.customElement('pfm-account-list', PfmAccountList);
    },
    components: {
        MdsModal,
        MdsIcon,
        MdsEmptyState,
        PfmDeleteAccountModal,
        PfmLoader,
        PfmUnlinkAccountModal
    },
    computed: {
        languageCode() {
            const { accountList } = this.settings;

            return accountList.settings.languageCode;
        },
        accountListConfig() {
            const { accountList } = this.settings;

            return JSON.stringify(accountList || {});
        },
        isCashflowEnabled() {
            const { accountList } = this.settings;

            return accountList.settings.isCashflowEnabled;
        },
        aggregationHost() {
            return whichString([
                this.settings.aggregationHost,
                this.hostNames.aggregationHost
            ]);
        },
        authContextString() {
            return JSON.stringify(this.authContext);
        },
        baaResponseErrors() {
            return '[]';
        },
        customFonts() {
            return this.settings.customFonts || false;
        },
        labels() {
            return {
                accountAggregation: this.translateWithMwc('accountAggregation'),
                accountLinked: this.translateWithMwc('accountLinked'),
                ariaInstitutionName: this.translateWithMwc('ariaInstitutionName'),
                closeModal: this.translateWithMwc('closeModal'),
                ariaDeleteAccountBtn: this.translateWithMwc('ariaDeleteAccountBtn'),
                ariaUnlinkAccountBtn: this.translateWithMwc('ariaUnlinkAccountBtn'),
                deleteAccount: {
                    acceptButtonLabel: this.translateWithMwc('deleteAccount.acceptButtonLabel'),
                    acceptText: this.translateWithMwc('deleteAccount.acceptText'),
                    detailText: this.translateWithMwc('deleteAccount.detail'),
                    durationLabel: this.translateWithMwc('deleteAccount.durationLabel'),
                    permanentLabel: this.translateWithMwc('deleteAccount.permanentLabel'),
                    rejectButtonLabel: this.translateWithMwc('deleteAccount.rejectButtonLabel'),
                    switchText: this.translateWithMwc('deleteAccount.switch'),
                    switchTextLink: this.translateWithMwc('deleteAccount.switchTextLink'),
                    title: this.translateWithMwc('deleteAccount.title'),
                    undoneLabel: this.translateWithMwc('deleteAccount.undoneLabel')
                },
                unlinkAccount: {
                    acceptButtonLabel: this.translateWithMwc('unlinkAccount.acceptButtonLabel'),
                    acceptText: this.translateWithMwc('unlinkAccount.acceptText'),
                    detailText: this.translateWithMwc('unlinkAccount.detail'),
                    duplicateLabel: this.translateWithMwc('unlinkAccount.duplicateLabel'),
                    durationLabel: this.translateWithMwc('unlinkAccount.durationLabel'),
                    permanentLabel: this.translateWithMwc('unlinkAccount.permanentLabel'),
                    rejectButtonLabel: this.translateWithMwc('unlinkAccount.rejectButtonLabel'),
                    switchText: this.translateWithMwc('unlinkAccount.switch'),
                    switchTextLink: this.translateWithMwc('unlinkAccount.switchTextLink'),
                    title: this.translateWithMwc('unlinkAccount.title'),
                    undoneLabel: this.translateWithMwc('unlinkAccount.undoneLabel')
                },
                baaErrors: {
                    title: this.translateWithMwc('baaErrors.title'),
                    badConfiguration: this.translateWithMwc('baaErrors.badConfiguration'),
                    badAuthentication: this.translateWithMwc('baaErrors.badAuthentication'),
                    internalError: this.translateWithMwc('baaErrors.internalError')
                }
            };
        },
        modalSize() {
            const { breakpointClass } = this;

            return [
                'pfm-layout-account-list--small',
                'pfm-layout-account-list--xsmall'
            ].indexOf(breakpointClass) > -1
                ? '300px'
                : '600px';
        },
        overrideCssFile() {
            return this.settings.overrideCssFile || false;
        },
        restUrl() {
            return whichString([
                this.settings.aggregationAPI,
                this.hostNames.aggregationAPI
            ]);
        },
        route() {
            // This will need additional logic for editing a credential later
            return this.baaRoute;
        },
        routes() {
            return {
                byAllAccounts: `${mwcComponentIdentifier}.byAllAccounts`,
                pfmAccountList: `${mwcComponentIdentifier}.pfmAccountList`,
                root: mwcComponentIdentifier
            };
        },
        translateFilePath() {
            return this.settings.translateFilePath || false;
        },
        uiConfigFile() {
            return this.settings.uiConfigFile || false;
        }
    },
    data() {
        return {
            version,
            accountToDelete: null,
            accountToUnlink: null,
            authContext: this.getAuthContext(),
            baaRoute: ADD_ACCOUNT_ROUTE,
            defaultConfig: {
                intlNamespace: this.mwcId,
                ...DEFAULT_CONFIG
            },
            isModalVisible: false,
            namespace: 'pfm-layout-account-list',
            useBaaModal: false,
            baaDataLoaded: false,
            showBaaError: false,
            baaErrorMessage: ''
        };
    },
    methods: {
        closeBaaModal() {
            this.isModalVisible = false;
            this.showBaaError = false;
        },
        baaServerError($event) {
            this.baaErrorMessage = this.labels.baaErrors[$event.type];
            this.showBaaError = true;
            setTimeout(() => {
                this.closeBaaModal();
            }, 5000);
        },
        /**
         * @function emitWrappedEvent
         * @description Repackages an event for an external consumer.
         * @param {string} eventName The new custom event name to use.
         * @param {event} $event The event to repackage.
         * @return {void}
         */
        emitWrappedEvent(eventName, $event) {
            const { detail } = $event;
            const customEvent = new CustomEvent(eventName, {
                detail
            });

            this.emitterFactory.call(this, this.routes.pfmAccountList, customEvent);
        },
        /**
         * @function getAccountFromEventData
         * @description Retrieves an `account` from `eventData` passed by an event
         * @param {object} eventData The data that contains the `account`
         * @returns {object} The `account` or `null`
         */
        getAccountFromEventData(eventData) {
            if (!isValidObject(eventData)) {
                return null;
            }

            if (isValidString(eventData.displayType)) {
                return eventData;
            }

            if (Array.isArray(eventData.detail)) {
                return eventData.detail[0] || null;
            }

            return null;
        },
        /**
         * @function getAuthContext
         * @description Retrieves the JWT token to pass to the BAA custom element.
         * @return {object} The authentication context for the BAA custom element.
         */
        getAuthContext() {
            let ecJWT = '';
            const windowJWT = window ? window.apiGatewayToken : '';

            // This attempts to find the configured `apiTokens.apiGatewayToken` passed to EC Loader
            try {
                ecJWT = window.morningstar.loader.context.getApiToken('apiGatewayToken');
            } catch (e) {
                // Do nothing
            }

            const jwt = whichString([ecJWT, windowJWT]);

            return {
                jwt: jwt.replace('Bearer ', '')
            };
        },
        hideBaaModal() {
            this.isModalVisible = false;
        },
        onBaaDataLoaded(eventDetail) {
            const customEvent = new CustomEvent('pfm-baa-data-loaded', {
                bubbles: true,
                detail: [eventDetail]
            });

            this.emitWrappedEvent('pfm-baa-data-loaded', customEvent);
            this.authContext = this.getAuthContext();
            this.useBaaModal = true;
            this.baaDataLoaded = true;
        },
        onNetWorthDataLoaded(eventDetail) {
            const customEvent = new CustomEvent('pfm-net-worth-data-loaded', {
                bubbles: true,
                detail: [eventDetail]
            });

            this.emitWrappedEvent('pfm-net-worth-data-loaded', customEvent);
            this.authContext = this.getAuthContext();
            this.useBaaModal = true;
        },
        onDeleteAccepted(eventDetail) {
            const customEvent = new CustomEvent('pfm-delete-accepted', {
                bubbles: true,
                detail: [eventDetail]
            });

            this.emitWrappedEvent('pfm-delete-accepted', customEvent);
            this.accountToDelete = null;
            this.accountToUnlink = null;
        },
        onShowDeleteAccount(eventData) {
            const account = this.getAccountFromEventData(eventData);

            this.accountToDelete = account;
            this.accountToUnlink = null;
        },
        onShowUnlinkAccount(eventData) {
            const account = this.getAccountFromEventData(eventData);

            this.accountToDelete = null;
            this.accountToUnlink = account;
        },
        onUnlinkAccepted(eventDetail) {
            const customEvent = new CustomEvent('pfm-unlink-accepted', {
                bubbles: true,
                detail: [eventDetail]
            });

            this.emitWrappedEvent('pfm-unlink-accepted', customEvent);
            this.resetAccounts(null);
        },
        onUpdateCredential($event) {
            const { route } = $event.detail[0];
            this.baaRoute = route;
            this.showBaaModal();
        },
        onUserExit() {
            this.isModalVisible = false;
            this.baaRoute = '';
            this.$nextTick(() => {
                this.baaRoute = ADD_ACCOUNT_ROUTE;
            });
        },
        resetAccounts(modalTxt) {
            this.accountToDelete = null;
            this.accountToUnlink = null;
            if (modalTxt === 'unlinkAccountModal') {
                forceNextTick(() => {
                    const unlinkAccountBtn = document.querySelector('#pfmUnlinkAccountBtn');
                    if (!unlinkAccountBtn) { return; }
                    unlinkAccountBtn.focus();
                });
            } else if (modalTxt === 'deleteAccountModal') {
                forceNextTick(() => {
                    const deleteAccountBtn = document.querySelector('#pfmDeleteAccountBtn');
                    if (!deleteAccountBtn) { return; }
                    deleteAccountBtn.focus();
                });
            }
        },
        showBaaModal() {
            this.isModalVisible = true;
        },
        accountLinkedFunc() {
            const { accountLinked } = this.labels;
            screenReaderStatus(accountLinked);
        }
    },
    mixins: [PfmContainerMixin],
    name: mwcComponentName
};
</script>

<style lang="scss" module>
@import "~cwp-dev-shared/styles/variables";
:global {
    .pfm-layout {
        .mds-icon__close {
            cursor: pointer;
            padding: $mds-space-2-x $mds-space-1-x;
        }
        .mds-button__pfm:disabled {
            opacity: 0.7
        }
        .mds-empty-state__pfm.mds-empty-state--large__pfm {
            margin: $mds-space-4-x auto 0;
        }

        .mds-modal__pfm.mds-modal--title-hidden__pfm .mds-section__header-container__pfm {
            min-height: 0;
        }

        .mds-modal__pfm.mds-modal--width-600px__pfm .mds-modal__wrapper__pfm {
            max-width: 625px;
        }

        .mds-modal__pfm .mds-modal__wrapper__pfm {
            max-height: none;
        }

        .mds-modal__pfm.mds-modal--title-hidden__pfm .mds-section__content__pfm {
            margin-top: 0;
        }
        .pfm-account-list__bullet {
            color: #6b6b6b;
        }

        .visually-hidden:not(:focus):not(:active) {
            clip: rect(0 0 0 0);
            clip-path: inset(100%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }
        .mds-notification__item--error,
        .mds-notification__item--warning {
            .mds-notification__status .mds-notification__icon {
                fill: #fff;
            }
        }
        .pfm-account-list__alert.mds-alert--error,
        .pfm-account-list__alert.mds-alert--warning {
            .mds-icon__pfm {
                fill: #fff;
                stroke: #fff;
            }
        }
    }
}

</style>
