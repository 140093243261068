import MwcVueHelperMixin, { MwcConfigMixin, MwcResponsiveMixin } from 'mwc-vue-component-helper';
import PfmSharedMixin from './pfm-shared';
import { getHostNames } from '../helpers/api';
import { createEventHub } from '../helpers/event-hub';
import { validObject, whichString } from '../helpers/valid';

/**
 * Expands on the MWC mixins:
 * https://msstash.morningstar.com/projects/TECHSTD/repos/morningstar-web-components/browse/packages/mwc-vue-component-helper/src/mwc-vue-base-mixin.js
 * https://msstash.morningstar.com/projects/TECHSTD/repos/morningstar-web-components/browse/packages/mwc-vue-component-helper/src/mwc-vue-config-mixin.js
 */

/**
 * @class pfm-container
 * @description A mixin for PFM MWC Container components
 */
export default {
    async beforeCreate() {
        const { $options } = this;
        const { propsData } = $options;
        const { mwcId } = propsData;

        this.eventHub = createEventHub(mwcId);
        this.eventHub.$on('middleware-ready', () => {
            this.middlewareReady = true;
        });
    },
    beforeDestroy() {
        this.onDestroy();
    },
    computed: {
        dataSourcesParsed() {
            return validObject(this.settings.dataSources, {});
        },
        dataSourcesString() {
            return JSON.stringify(this.dataSourcesParsed);
        },
        hostNames() {
            const { pfmApiEnv } = this;

            return getHostNames(pfmApiEnv);
        },
        linkDomain() {
            const { hostNames, settings } = this;
            const { cwpDomain } = hostNames;

            return whichString([settings.linkDomain, cwpDomain]);
        },
        mdsComponentSize() {
            return this.options.mdsSize;
        },
        pfmApiEnv() {
            const { settings } = this;

            return whichString([window.pfmApiEnv, settings.pfmApiEnv, 'prod']);
        }
    },
    data() {
        return {
            /**
             * @property {boolean} middlewareReady Has the data layer completed its setup tasks?
             */
            middlewareReady: false,
            /**
             * @property {Array} providedProps A list of immutable component properties to share with children
             */
            providedProps: ['eventHub', 'mwcId']
        };
    },
    methods: {
        emitLayoutEvent(settings, source) {
            const { eventHub, mwcId } = this;
            const {
                action = {},
                bubble = false,
                route
            } = settings;

            const actionDefaults = source ? {
                detail: source.detail,
                target: source.target,
                type: source.type
            } : {};

            if (source && !bubble) {
                source.stopPropagation();
            }

            eventHub.$emit('mstar-experience-manager', {
                mwcId,
                route,
                action: {
                    ...actionDefaults,
                    ...action
                }
            });
        },
        emitterFactory(route, $event) {
            this.emitLayoutEvent({
                route
            }, $event);
        },
        onDestroy() {
            this.emitLayoutEvent({
                action: {
                    target: this.$el.parentElement,
                    type: 'pfm-layout-destroy'
                },
                route: this.routes.root
            });
        },
        onRender() {
            const { mwcId } = this;

            this.emitLayoutEvent({
                action: {
                    detail: {
                        mwcId,
                        id: mwcId
                    },
                    target: this.$el.parentElement,
                    type: 'pfm-layout-render'
                },
                route: this.routes.root
            });
        }
    },
    mixins: [PfmSharedMixin, MwcVueHelperMixin, MwcConfigMixin, MwcResponsiveMixin],
    mounted() {
        const { linkDomain } = this;

        // The PFM /task API defaults to relative paths
        // This allows us to set a root for them
        if (window
            && window.PFM_ORCHESTRATION
            && linkDomain) {
            window.PFM_ORCHESTRATION.linkDomain = linkDomain;
        }

        this.$nextTick(this.onRender);
    },
    props: {
        config: {
            default: () => ({}),
            type: [String, Object]
        },
        mdsSize: {
            default: 'medium',
            type: String
        },
        mwcId: {
            required: true,
            type: String
        }
    },
    provide() {
        const { providedProps } = this;

        return providedProps.reduce((provider, obj) => {
            Object.defineProperty(provider, obj, {
                enumerable: true,
                get: () => this[obj] || this[`$_${obj}`]
            });

            return provider;
        }, {});
    }
};
