import axios from 'axios';

export const ACCEPT = {
    ALL: 'application/json, text/plain, *.*',
    JSON: 'application/json',
    TEXT: 'text/plain'
};

export const CONTENT_TYPE = {
    APPLICATION: 'application/octet-stream',
    JSON: 'application/json',
    TEXT: 'text/plain'
};

export const HEADERS = {
    CSRFTOKEN: 'x-csrf-token',
    JSESSIONID: 'x-jsessionid'
};

export const HTTP_METHOD = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
    HEAD: 'head'
};

export const RESPONSE_TYPE = {
    ARRAY_BUFFER: 'arraybuffer',
    BLOB: 'blob',
    DOCUMENT: 'document',
    JSON: 'json',
    TEXT: 'text',
    STREAM: 'stream'
};

/**
 * @typedef {Object} RequestMetadata
 * @property {String} url Absolute or relative url
 * @property {String} method HTTP_METHOD
 * @property {String} responseType RESPONSE_TYPE
 * @property {Object} params Key-value pair representation for the query parameters
 * @property {Object} payload Data payload for POST, PUT, PATCH
 * @property {Object} headers Key-value pair representation for headers
 * @property {Boolean} withCredentials Include cookies for cross-origin requests
 * @property {Number} timeout Timeout limit (in milliseconds) to abort the request
 */

/**
 * @function http
 * @description Uses `axios` to place an AJAX call with common settings applied
 * @param {RequestMetadata} metadata Details to make the HTTP request
 * @returns {Promise} The AJAX call promise
 */
export function http(metadata) {
    const {
        body,
        params,
        payload,
        headers,
        timeout,
        url,
        method = HTTP_METHOD.GET,
        responseType = RESPONSE_TYPE.JSON,
        returnData = false,
        withCredentials = true
    } = metadata;

    return axios.request({
        headers,
        method,
        params,
        responseType,
        timeout,
        url,
        withCredentials,
        data: payload || body,
        validateStatus: status => status < 400
    }).then(response => {
        // We use Axios and MWC depending on context right now, Axios has a data prop
        if (returnData === true && response && response.data) {
            return response.data;
        }

        return response;
    });
}
