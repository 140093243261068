import COMMON_SETTINGS from 'pfm-shared/config/common-settings';
import { RESPONSE_TYPE } from 'cwp-dev-shared/helpers/http';
import labels from './labels';

export default {
    labels,
    settings: {
        ...COMMON_SETTINGS,
        accountList: {
            settings: {
                notificationStyle: 'internal',
                showPending: false,
                isCashflowEnabled: true,
                languageCode: 'en',
                readMode: false,
                showEmptyStateIcon: true
            }
        },
        customFonts: '',
        dataSources: {
            root: {
                pfmServerConfig: {
                    getTask: {
                        url: '/personal-financial-management/v1/task',
                        method: 'POST'
                    },
                    setPfmOnboardingTarget: {
                        url: '/personal-financial-management/v1/pfm-onboarding-target',
                        method: 'PUT'
                    },
                    getUserStatus: {
                        url: '/personal-financial-management/v1/user-status',
                        method: 'GET'
                    },
                    setUserStatus: {
                        url: '/personal-financial-management/v1/user-status',
                        method: 'PUT'
                    }
                }
            },
            pfmLayoutAccountList: {
                pfmAccountList: {
                    deleteCredentialSource: {
                        url: '/aggapi/v1/credentials',
                        method: 'DELETE',
                        responseType: RESPONSE_TYPE.TEXT
                    },
                    deleteOfficeAccountSource: {
                        url: '/personal-financial-management/v1/account',
                        method: 'DELETE'
                    },
                    deletePfmAccountSource: {
                        url: '/personal-financial-management/v1/account',
                        method: 'DELETE'
                    },
                    getAggregationSource: {
                        url: '/aggapi/v1/credentials/accounts',
                        method: 'GET'
                    },
                    getNetWorthSource: {
                        url: '/personal-financial-management/v1/summary-per-category',
                        method: 'GET'
                    },
                    unlinkAccountSource: {
                        url: '/aggapi/v1/accounts',
                        method: 'DELETE',
                        responseType: RESPONSE_TYPE.TEXT
                    }
                }
            }
        },
        overrideCssFile: '',
        translateFilePath: '',
        uiConfigFile: ''
    }
};
